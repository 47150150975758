// =============================================
//                   BASE

import { ButtonTopicItemTypes } from './contentful.types';

// =============================================
export type PagePropTypes = {
  params: {
    langAndSlug: [string | undefined, string];
  };
};

export type ClinicalSettingTypes = {
  buttons: ButtonTopicItemTypes[];
  icon: string;
  pretitle: string;
  url: string;
  clinicTitle?: string;
  title: string;
  description?: string | React.ReactNode;
  carouselRef?: React.Ref<HTMLDivElement>;
};

export enum TailwindBreakPoints {
  xsm = 425,
  sm = 500,
  md = 768,
  lg = 1024,
  xl = 1280,
  _2xl = 1444,
  _3xl = 1536,
}

export enum BLOCKS {
  HEADING_1 = 'heading-1',
  HEADING_2 = 'heading-2',
  HEADING_3 = 'heading-3',
  HEADING_4 = 'heading-4',
  HEADING_5 = 'heading-5',
  HEADING_6 = 'heading-6',
  PARAGRAPH = 'paragraph',
  EMBEDDED_ASSET = 'embedded-asset-block',
  EMBEDDED_ENTRY = 'embedded-entry-block',
  QUOTE = 'blockquote',
  UL_LIST = 'unordered-list',
  OL_LIST = 'ordered-list',
  TABLE = 'table',
  TABLE_ROW = 'table-row',
  TABLE_CELL = 'table-cell',
  TABLE_HEADER_CELL = 'table-header-cell',
  HR = 'hr',
}

// =============================================
//         SESSION STORAGE TYPES
// =============================================
export type SessionStorageKeyTypes = 'categorySlug';
export type SessionStorageValueTypes = any; // Add more types

export type GetSessionStorageParamTypes = {
  key: SessionStorageKeyTypes;
  defaultValue: SessionStorageValueTypes;
};

export type SessionStorageParamTypes = {
  key: SessionStorageKeyTypes;
  value: SessionStorageValueTypes;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GetSessionStorageReturnTypes = any;

// =============================================
//                   CSS TYPES
// =============================================
export type ObjectFitTypes = 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
