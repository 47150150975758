import React from 'react';

// Custom hook that skips the effect on initial mount with TypeScript support
function useLayoutEffectExceptOnMount(effect: React.EffectCallback, deps?: React.DependencyList) {
  const isInitialMount = React.useRef(true);

  React.useLayoutEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      return effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export default useLayoutEffectExceptOnMount;
