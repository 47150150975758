import { useEffect, useState } from 'react';

const useAllImagesLoaded = () => {
  const [allLoaded, setAllLoaded] = useState(false);

  useEffect(() => {
    const mainContainer = document.querySelector('main');
    const images = mainContainer ? Array.from(mainContainer.querySelectorAll('img')) : [];

    if (images.length === 0) {
      setAllLoaded(true);
      return;
    }

    let loadedCount = 0;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onImageLoad = (event: Event) => {
      loadedCount++;
      if (loadedCount >= Math.ceil(images.length * 0.5)) {
        setAllLoaded(true);
      }
    };

    images.forEach((img) => {
      const imageElement = img as HTMLImageElement;
      if (imageElement.complete) {
        onImageLoad(new Event('load'));
      } else {
        imageElement.addEventListener('load', onImageLoad, { once: true });
        imageElement.addEventListener('error', onImageLoad, { once: true });
      }
    });

    return () => {
      images.forEach((img) => {
        const imageElement = img as HTMLImageElement;
        imageElement.removeEventListener('load', onImageLoad);
        imageElement.removeEventListener('error', onImageLoad);
      });
    };
  }, []);

  return allLoaded;
};

export default useAllImagesLoaded;
